<template>
  <v-container fluid>
    <ShopTabs />
    <v-row>
      <v-col cols="9">
        <div class="d-flex justify-space-between align-center gap-x-2 mb-3">
          <h3 class="font-semibold text-blue">
            Categories
          </h3>
          <div class="d-flex align-center gap-x-2">
              <v-text-field
                v-model="search"
                class="search"
                clearable
                dense
                flat
                hide-details="auto"
                label="Search"
                solo
                @keyup.enter="getProducts"
                @click:append="getProducts"
                @click:clear="clearSearch"
              >
                <template #prepend-inner>
                  <SearchIcon/>
                </template>
              </v-text-field>
          </div>
        </div>
        <div class="d-flex align-center gap-x-4 overflow-x-auto pb-3 mb-2">
          <v-btn
              v-for="(category,ci) in categories" :key="category.id"
              :class="{'active':(selectedTab === ci) }"
              class="category text-capitalize"
              elevation="0"
              @click="handleTabChange(ci)"
          >
            {{ category.name }}
          </v-btn>
        </div>  
        <v-row>
          <v-col v-for="(product,index) in products" :key="index" lg="3" md="6" sm="12">
            <RetailProductCard :product="product"  @addToCart="addToCart" @removeCartItem="removeCartItem" :key="`p_${index}`" :refreshProductItem="refreshProductItem" :defaultImges="defaultImges" />
          </v-col>
        </v-row> 
      </v-col>
    </v-row>

    <ShopCustomerPopup
        v-if="showCustomerPopup"
        bookingType="retail"
        :show-customer-popup="showCustomerPopup"
        @close="closeCustomerPopup"
        @setCustomers="setCustomers"
    />
    <PackageCardPopup v-if="offers && offers.length" :show-offer-popup="showOfferPopup" :offers="offers" @close="closeOfferPopup"/>
  </v-container>
</template>

<script>
import ShopTabs from "@/views/Shop/ShopTabs.vue";
import SearchIcon from "@/assets/images/misc/search.svg";
import RetailProductCard from "@/components/Shop/Retail/RetailProductCard.vue";
import images from "@/utils/images";
import ShopCustomerPopup from "@/components/Shop/ShopCustomerPopup.vue";
import PackageCardPopup from "@/components/Shop/Offers/PackageCardPopup.vue";
export default {
  name: "ShopRetail",
  components: {PackageCardPopup, RetailProductCard, ShopTabs, SearchIcon,ShopCustomerPopup},
  mounted() {
    this.getCategories();
    this.getProducts();
  },
  watch: {
      page() {
        this.getProducts();
      },
      reloadShopModule(val){
        if(val && val === 'retail'){
          console.log("venue service id: ",this.venueServiceId);
          this.page = 1;
          this.totalPages = 1;
          if(this.venueServiceId) {
            this.getCategories();
            this.getProducts();
          }else{
            this.categories = [];
            this.products = [];
          }
        }
      }
  },
  computed: {
    venueServiceId() {
        const filteredService = this.$store.getters.getShopVenueServices.data.find(item => item.name.toLowerCase() === "pos");
        if (filteredService) {
            return filteredService.id;
        } else {
            return null;
        }
    },
    reloadShopModule(){
      return this.$store.getters.getReloadShopModule;
    },
    getCurrentVenueId(){
      return this.$store.getters.svid;
    },
    getCartItems(){
      return this.$store.getters.getCartItems;
    },
  },
  data() {
    return {
      showCustomerPopup: false,
      defaultImges: images,
      search: "",
      products: [],
      page: 1,
      totalPages: 1,
      perPage: 20,
      selectedCategory:'all',
      refreshProductItem: 0,
      categories: [],
      selectedTab: 0,
      selectedProduct: null,
      offers: [],
      showOfferPopup: false,
    }
  },
 
  methods: {
    getProducts() {
      const venueId = this.getCurrentVenueId;
      this.showLoader("Loading");
      let url = `venues/shop/retail/products?venueId=${venueId}&page=${this.page}&per_page=${this.perPage}&category=${this.selectedCategory}`;
      url += `${this.search ? "&product_name=" + this.search : ""}`;
      this.$http.get(url).then((response) => {
          if (response.status == 200) {
              this.products = [];
              const data = response.data.data;
              if(data && data.length){
                  this.products = data;
                  this.totalPages = response.data.total_pages;
                  this.$nextTick(() => {
                      this.refreshProductItem++;
                  });
              }else{
                  this.products = [];
                  this.totalPages = 1;
              }
              this.hideLoader();
          }
      }).catch((error) => {
            this.hideLoader();
          this.errorChecker(error);
      });
      
    },
    getCategories() {
      const venueId = this.getCurrentVenueId;
      // this.showLoader("Loading");
      if (this.venueServiceId) {
          let url = `venues/shop/retail/categories?venueId=${venueId}&venue_service_id=${this.venueServiceId}`;
          this.$http.get(url).then((response) => {
              if (response.status == 200) {
                  const data = response.data.data;
                  if (data && data.length) {
                      this.categories = [{ id: 0, name: 'All' }, ...data];
                  } else {
                      this.categories = [];
                  }
                  this.hideLoader();
              }
          }).catch((error) => {
              this.hideLoader();
              this.errorChecker(error);
          });
      } else {
        this.categories = [];
      }
    },
    handleTabChange(newTabIndex) {
      this.page = 1;
      this.selectedTab = newTabIndex;
      if(this.categories[newTabIndex].id == 0 && this.selectedCategory != "all"){
          this.selectedCategory = 'all';
          this.getProducts();
      }else if(this.categories[newTabIndex].id > 0){
          this.selectedCategory = this.categories[newTabIndex].id;
          this.getProducts();
      }
    },
    clearSearch() {
      this.search = "";
      this.getProducts();
    },

    addToCart(data){
      if(data.isPopup){
        this.selectedProduct = {...data};
        this.showCustomerPopup = true;
      }else{
        this.selectedProduct = null;
        this.addOrUpdateCartItem(data)
      }
    },

    addOrUpdateCartItem(data) {
      let venueId = this.getCurrentVenueId;
      let foundProduct = this.products.find(product => product.id === data.product_id);
      if(! foundProduct){
        return this.showError('Product not found');
      }
      let mainProductName = "";
      let category=foundProduct.category;
      let productImage = foundProduct.image;
      if (data.vp_id) {
        mainProductName = foundProduct.name;
        if (foundProduct.variants && foundProduct.variants.length) {
          foundProduct = foundProduct.variants.find(v => v.id === data.vp_id);
          if (foundProduct) {
            mainProductName = foundProduct.name;
          }
        }
      }
      let cartData = this.getCartItems.find( (ci) => ci.type === "retail" && ci.venueId === venueId);
      let existingProduct = null;
      if(cartData){
        existingProduct = cartData.products.find(item => item.product_id === foundProduct.id);
      }
      if(foundProduct.enable_retail_inventory){
        if(existingProduct && foundProduct.stock_level <  data.quantity){
          this.showError("Only "+foundProduct.stock_level+" quantity available in stock");
          return;
        }else{
          if(foundProduct.stock_level < 1){
            if(foundProduct.stock_level == 0){
                this.showError("Product Out Of Stock");
            }else{
                this.showError("Only "+foundProduct.stock_level+" quantity available in stock");
            }
            return;
          }
        }
      }
      if (existingProduct) {
        existingProduct.quantity = data.quantity;
        this.checkProductOffers();
      } else {
        mainProductName = foundProduct.name;
        // If the product does not exist in the cart, add it to the cart items
        let product  = {
            id: foundProduct.id,
            product_id: foundProduct.id,
            product_type_id: 9,
            name:mainProductName,
            price:foundProduct.price,
            total_price: (foundProduct.price) + (foundProduct.tax_amount),
            quantity: data.quantity,
            tax:foundProduct.tax_amount,
            category:category,
            image:productImage,
            vp_id:data.vp_id,
            enable_retail_inventory:foundProduct.enable_retail_inventory,
            stock_level:foundProduct.stock_level
        };

        this.$store.dispatch("addRetailToCart",{product: product,venueId:venueId}).then( () => {
          this.checkProductOffers();
        });
      }
    },
    removeCartItem(data){
      let oi = this.getCartItems.findIndex( (ci) => ci.type == "retail");
      if(oi >= 0){
        let pi = null;
        if(data.vp_id){
           pi = this.getCartItems[oi].products.findIndex(item => item.product_id === data.vp_id);
        }else{
          pi = this.getCartItems[oi].products.findIndex(item => item.product_id === data.product_id);
        }
        if(pi>=0){
          if(this.getCartItems[oi].products[pi].quantity > 0){
            this.$store.dispatch("updateRetailQtyCartItem",{oi: oi, pi: pi}).then( () => {
              this.checkProductOffers();
            });
          }
        }
      }
    },
    closeCustomerPopup() {
      this.selectedProduct = null;
      this.showCustomerPopup = false;
      
    },
    setCustomers(customers) {
      let venueId = this.getCurrentVenueId;
      customers.forEach((item) => {
        delete item.search;
        delete item.nameSearch;
      });
      this.$store.dispatch("addRetailCustomer",{customers:customers,venueId:venueId,venue_service_id: this.venueServiceId}).then( () => {
        let d = {...this.selectedProduct};
        this.addOrUpdateCartItem(d);
        this.selectedProduct = null;
        this.showCustomerPopup = false;
        // this.checkProductOffers();
      });
    },
    checkProductOffers(){
      this.offers = [];
      this.$store.dispatch('searchOffer').then( (response) => {
          if (response && response.status == 200 && response.data.status == true) {
              const offerPackages = response.data.data || null;
              if(offerPackages && offerPackages.length > 0) {
                this.offers = offerPackages;
                this.showOfferPopup = true;
              }else{
                this.offers = [];
              }
          }
      });
    },
    closeOfferPopup(){
      this.showOfferPopup = false;
      this.offers = [];
    },
  },
   
}
</script>

<style lang="scss" scoped>
::v-deep .search {
  border-radius: 4px;
  border: 1px solid #EFEDED;
  background: #FFF;
  max-width: 250px;

  .v-label {
    opacity: 0.3;
  }
}

.category {
  border-radius: 4px;
  border: 1px solid rgba(17, 42, 70, 0.10);
  background: #FFF !important;
  padding: 0.875rem 1.25rem;
  cursor: pointer;

  &.active {
    border-radius: 4px;
    border: 1px solid #4FAEAF;
    color: #4FAEAF;
    background: rgba(79, 174, 175, 0.10) !important;
  }
}
</style>
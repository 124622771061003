<template>
  <v-card class="shadow-0 product-card rounded-2">
    <div class="px-2 py-2 pb-4">
      <v-img
          :aspect-ratio="1"
          :src="product.image ? s3BucketURL + product.image : defaultImges.default"
          height="200"
          class="rounded-2"
      />
      <h3 class="text-base font-semibold my-2 line-clamp-1">
        {{ product.name }}
      </h3>
      <div class="d-flex justify-space-between align-center pv-div">
        <p class="teal--text font-medium mb-0">
          <span class="price">{{ (selectedVariant? selectedVariant.total_price:product.total_price) | toCurrency }}</span>
        </p>
        <div class="font-medium vdiv" v-if="product.variants && product.variants.length > 0" >
          <v-select
            v-model="selectedVariant"
            :items="product.variants"
            item-text="attribute_name"
            item-value="id"
            height="30px"
            return-object
            hide-details="auto"
            class="q-text-field shadow-0 veriation-ps"
            dense
            outlined
            :menu-props="{ bottom: true, offsetY: true }"
            background-color="#fff"
            required
            :rules="[(v) => !!v || 'Product variant is required']"
          ></v-select>
        </div>
      </div>
      <div class="vq-div mt-1">
      
        <!-- <div class="stock-qty" v-if="product.enable_retail_inventory">
            Stock: {{ selectedVariant?selectedVariant.stock_level: product.stock_level}}
        </div> -->
        <div class="quantity qdiv mt-2">
         <template v-if="quantity>0">
           <button type="button" @click="decrementQuantity">
             <MinusIcon v-if="quantity>=1"/>
           </button>
           <div class="font-medium text-center" style="min-width: 40px">
             {{ quantity }}
           </div>
           <button type="button" @click="incrementQuantity">
             <PlusIcon/>
           </button>
         </template>
          <template v-else>
            <button type="button" @click="incrementQuantity">
              <CartIcon />
            </button>
          </template>
        </div>
        <div class="font-medium cart-btn-div">
          <v-btn
              class="white--text blue-color mt-2 shadow-0 atc-btn"
              height="32"
              text
              @click="addToCartBtnClick"
            >
            Add
          </v-btn>
        </div>
        
      </div>
    </div>
  </v-card>
</template>

<script>

import PlusIcon from "@/assets/images/retail/plus-icon.svg";
import MinusIcon from "@/assets/images/retail/minus-icon.svg";
import CartIcon from "@/assets/images/retail/cart-icon.svg";
export default {
  name: "RetailProductCard",
  components: {MinusIcon, PlusIcon,CartIcon},
  props: {
    product: Object,
    defaultImges: { type: Object, default: ()=>{}},
  },
  mounted() {
      this.selectedVariant = this.product.variants.length?this.product.variants[0]:null;
  },
  computed: {
    getCurrentVenueId(){
      return this.$store.getters.svid;
    },
    venueServiceId() {
      const filteredService = this.$store.getters.getShopVenueServices.data.find(item => item.name.toLowerCase() === "pos");
      if (filteredService) {
        return filteredService.id;
      } else {
        return null;
      }
    },
  },
  data() {
    return {
      quantity: 0,
      selectedVariant: null,
      variant_stock_level: 0,
    }
  },
  methods: {
    incrementQuantity() {
      // this.addToCart();
      this.quantity = this.quantity+1;
    },
    decrementQuantity() {
      if (this.quantity > 0) {
        this.addToCart('remove');
        this.quantity = this.quantity-1;
      }
    },
    addToCart(action = "add", isPopup = false){
      let data = { product_id: this.product.id };
      if (this.selectedVariant && this.selectedVariant.id) {
        if(action == "add"){
          data.vp_id = this.selectedVariant.id;
          data.quantity = this.quantity;
          data.isPopup = isPopup;
          this.$emit("addToCart", data);
        }else if(action == "remove"){
          data.vp_id = this.selectedVariant.id;
          this.$emit("removeCartItem", data);
        }
      } else {
        if(action == "add"){
          data.vp_id = null;
          data.isPopup = isPopup;
          data.quantity = this.quantity;
          this.$emit("addToCart", data);
        }else if(action == "remove"){
          data.vp_id = null;
          this.$emit("removeCartItem", data);
        }
      }
    
    },
    addToCartBtnClick(){
      // check customer info is added in cart or not
      let cartItems = this.$store.getters.getCartItems;
      if(this.quantity == 0){
        this.quantity = 1;
      }
      if(cartItems.length){
        let retail = cartItems.find( (item) => item.type === 'retail' && item.venueId === this.getCurrentVenueId);
        if(typeof retail != "undefined" && retail.customers && retail.customers.length){
          this.addToCart('add');
        }else{
          this.addToCart('add',true);
        }
      }else{ 
        this.addToCart('add',true);
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .veriation-ps{
    .v-input__control {
      height: 32px !important;
    }
    .v-input__control .v-input__slot {
      height: 32px !important;
      min-height: 32px !important;
    }
    .v-input__icon {
      height: 18px;
    }
  } 
}
.quantity{
  button{
    height: 28px;
  }
}
.product-card{
  border-radius: 0.5rem;
  border: 1px solid #EAEAEA;
  background: #FFF;
}
.stock-qty{
  width: 100%;
  font-size: 12px;
}
.vq-div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
}

.vdiv {
    width: 50%;
}
.quantity.qdiv {
    display: inline-flex;
}
.cart-btn-div {
    width: 100%;
    display: grid;
}
.pv-div {
    min-height: 34px;
}

</style>